import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Institute from './pages/Institute';
import Courses from './pages/Courses';
import CoursesDetails from './pages/CoursesDetails';
import { FilterProvider } from './contexts/FilterContext';
import Sample from './components/Privacy';
import Terms from './components/Terms';
import InstituteHomepage from './pages/InstituteHomepage';
import ScrollToTopOnPageChange from './components/ScrollToTopOnPageChange';
import ApiData from './components/ApiData';
import Viewinstitute from './components/viewinstitutes';
import ApiCourseDetails from './pages/ApiCourseDetails';
import UniversityprofileApi from './pages/UniversityprofileApi';

import { AuthProvider, useAuth } from './contexts/AuthContext';
import LoginPage from './pages/Admin/LoginPage';
import AdminHomePage from './pages/Admin/AdminHomePage';
import AdminInstitute from './pages/Admin/ViewInstitute'
import Admininstitutedelete from './pages/Admin/deletedinstitute'

import Addcategory from './pages/Admin/Addcategory'

const AdminRoute = () => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? <AdminHomePage /> : <LoginPage />;
};

const App = () => {
  return (
    <AuthProvider>
      <BrowserRouter>
        <ScrollToTopOnPageChange />
        <FilterProvider>
          <Routes>
            <Route index element={<Home />} />
            <Route path="/" element={<Home />} />
            <Route path="/institute" element={<Institute />} />
            <Route path="/shortcourse/:shortname" element={<Courses />} />
            <Route path="/shortcourse/course-details/:courseId" element={<CoursesDetails />} />
            <Route path="/docs/privacyPolicy" element={<Sample />} />
            <Route path="/docs/termsofUse" element={<Terms />} />
            <Route path="/shortcourse/course-details/institute/:institutionName" element={<InstituteHomepage />} />
            <Route path='/search' element={<ApiData />} />
            <Route path="/course/:courseId" element={<ApiCourseDetails />} />
            <Route path='/course/institute/:university_user_id' element={<UniversityprofileApi />} />
            <Route path='/viewinstitutes' element={<Viewinstitute />} />
            {/* Admin Path details */}
            <Route path='/admin' element={<AdminRoute />} />
            <Route path='/admin/category' element={<Addcategory />} />
            <Route path='/admin/institute' element={<AdminInstitute />} />
            <Route path='/admin/institute/delete' element={<Admininstitutedelete />} />
          </Routes>
        </FilterProvider>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
