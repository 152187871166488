import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Contact from '../components/Contact';
import Navbar from '../components/Navbar';
import { motion } from 'framer-motion';
import { getcoursebyid } from '../services/CommonApi';
import LaunchIcon from '@mui/icons-material/Launch';

export default function CoursesDetails() {

  const location = useLocation();
  const { course_id} = location.state || '';
  const navigate = useNavigate();
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setdata] = useState('')
  const [teaching_mode, setteachingmode] = useState([])
  const [emailInfo, setEmailInfo] = useState('');
  const [phoneInfo, setPhoneInfo] = useState([]);
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const showButtonPosition = 300; // Adjust this value as needed

    if (scrollPosition > showButtonPosition) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleClick = () => {
    navigate(`/shortcourse/course-details/institute/${data.institution_id}`, {
      state: { institute_id: data.institution_id },
    });
  };

  const fetch = async () => {
    const result = await getcoursebyid(course_id)
    setdata(result)
    // Set email and phone data in state
    const email = result.contact_infos.find(info => info.type === 'email');
    const phones = result.contact_infos.filter(info => info.type === 'phone');

    setEmailInfo(email?.email_address || 'Not Available');
    setPhoneInfo(phones.length > 0 ? phones.map(info => info.phone_number) : ['Not Available']);

    setteachingmode(result.teaching_mode)
  }

  useEffect(() => {
    setIsLoaded(true);
    fetch()
  }, []);

  return (
    <>
      <div className={`dup-body ${isLoaded ? 'loaded' : ''}`}>
        <div className='dup-body-wrap'>
          <Navbar title='SavvyPool' nav1="Home" nav2="List your institute" />
          <motion.div
            style={{ marginBottom: '1rem' }}
            initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <div className='Full-details'>
              <div className='sponsored-content'>
                {/* <h1>Sponsored Content</h1> */}
              </div>
              <div className='inst-detail'>
                <div className='Institute-details'>
                  <img src={data.banner_url ? data.banner_url : 'https://images.pexels.com/photos/2973323/pexels-photo-2973323.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'} className='insititute-banner' alt=''></img>
                  <img src={data.logo_url ? data.logo_url : '/logo just.png'} className='Institute-details-image-2' alt='instituteimg' />
                  <div className='Institute-address-none'>
                    <div className='institute-website-redirect'>
                      <h1 onClick={() => {
                        switch (data.institution_id) {
                          default:
                            navigate(`/shortcourse/course-details/institute/${data.institution_id}`, { state: { institute_id: data.institution_id } });
                            break;
                        }
                      }}>{data.institution_name}</h1>
                    </div>
                  </div>
                </div>

                <h1 className='course-category'>{data.course_name}</h1>

                <div className='course-dur'>
                  <p style={{ marginBottom: '3px', fontWeight: 'bold' }}>Course category</p>
                  <h2>{data.course_category}</h2>
                </div>
                <hr className='hr-tag'></hr>

                <div>
                  <p className='p-category' style={{ marginBottom: '3px', fontWeight: 'bold' }}>Name of Institute</p>
                  <h1 onClick={handleClick} style={{ textDecoration: 'underline', display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                    {data.institution_name}
                    {/* <span style={{ marginLeft: '8px', cursor: 'pointer' }} > */}
                    <LaunchIcon style={{ cursor: 'pointer', marginLeft: '5px', fontSize: 'small' }} />
                    {/* </span> */}
                  </h1>
                  <hr className='hr-tag'></hr>
                </div>


                <div>
                  <p classname='p-category' style={{ marginBottom: '3px', fontWeight: 'bold' }}>Course Name</p>
                  <h1>{data.course_name}</h1>
                  <hr className='hr-tag'></hr>
                </div>

                <div>
                  <p classname='p-category' style={{ marginBottom: '3px', fontWeight: 'bold' }}>Course Description</p>
                  <h1>{data.course_description}</h1>
                  <hr className='hr-tag'></hr>
                  <button className={`imintrestedbtn ${isVisible ? 'show' : ''}`}
                    onClick={() => {
                      const message = `Hello! ${data.institution_name},\n\nI came to know about your ${data.course_name} program and I'm reaching out to you on behalf of SavvyPool.\nI'm eager to learn more about this exciting program.\nThank you!`;
                      const whatsappUrl = `https://api.whatsapp.com/send?phone=+919778399433&text=${encodeURIComponent(message)}`;
                      window.open(whatsappUrl, "_blank");
                    }}>
                    I'm Interested
                  </button>
                  {data.categories && (
                    <>
                      <p className='p-category' style={{ marginBottom: '3px', fontWeight: 'bold' }}>Category</p>

                      <div className='course-dur' style={{ justifyContent: "space-evenly", flexWrap: 'wrap' }}>
                        {data.categories.map((category, index) => (
                          <h2 style={{ margin: '6px' }} key={index}>{category.category_name}</h2>
                        ))}
                      </div>

                      <hr className='hr-tag' />
                    </>
                  )}


                  <div className='p-category'>
                    <p classname='p-category' style={{ marginBottom: '3px', fontWeight: 'bold' }}>Course Curriculum</p>
                    {data.course_curriculum ? <h2>{data.course_curriculum}</h2> : <h2>Not Available</h2>}
                  </div>
                  <hr className='hr-tag'></hr>

                  <p className='p-category' style={{ marginBottom: '3px', fontWeight: 'bold' }}>Mode Of Teaching</p>
                  <div className='course-dur' style={{ justifyContent: "end" }}>
                    {teaching_mode && teaching_mode.map((data, index) => (
                      <h2 style={{ marginLeft: '10px' }} key={index}>{data}</h2>
                    ))}
                  </div>
                  <hr className='hr-tag'></hr>
                  <div className='course-dur'>
                    <p classname='p-category' style={{ marginBottom: '3px', fontWeight: 'bold' }}>Course Duration</p>
                  </div>
                  <div className='course-dur' style={{ justifyContent: data.course_duration_month && data.course_duration_year ? "space-evenly" : "end", alignItems: 'center' }}>
                    {data.course_duration_year && <h2 classname='p-category' style={{
                      padding: window.innerWidth <= 600 ? "5px 25px" : "5px 70px"
                    }}>{data.course_duration_year + (data.course_duration_year > 1 ? " Years" : " Year")}</h2>}
                    {data.course_duration_month && data.course_duration_year ? (<h1>and</h1>) : ""}
                    {data.course_duration_month && <h2 style={{
                      padding: window.innerWidth <= 600 ? "5px 25px" : "5px 70px"
                    }} classname='p-category'>{data.course_duration_month + (data.course_duration_month > 1 ? " Months" : " Month")}</h2>}
                  </div>
                  <hr className='hr-tag'></hr>

                  {data.course_fee && <><div className='course-dur'>
                    <p classname='p-category' style={{ marginBottom: '3px', fontWeight: 'bold' }}>Course Fee</p>
                    {data.course_fee ? (<h2>{"Rs. " + data.course_fee + " / " + (data.course_fee_type ? data.course_fee_type.charAt(0).toUpperCase() + data.course_fee_type.slice(1) : '')}</h2>) : (<p>Not Available</p>)}
                  </div>
                    <hr className='hr-tag'></hr>
                  </>}
                  {data.course_locations && (
                    <>
                      <p className='p-category' style={{ marginBottom: '3px', fontWeight: 'bold' }}>Locations</p>
                      <div className='course-dur' style={{ justifyContent: "end" }}>
                        {data.course_locations.map((location, index) => (
                          <h2 key={index} style={{ margin: '6px' }}>{location.city} - {location.state}</h2>
                        ))}
                      </div>
                      <hr className='hr-tag' />
                    </>
                  )}

                  <div>
                    <p classname='p-category' style={{ marginBottom: '3px', fontWeight: 'bold' }}>Contact Email</p>
                    <h1>{emailInfo}</h1>
                    <hr className='hr-tag'></hr>
                  </div>
                  <div>
                    <p classname='p-category' style={{ marginBottom: '3px', fontWeight: 'bold' }}>Contact Number</p>
                    {phoneInfo.map((phone, index) => (
                      <h1 key={index}>{phone}</h1>
                    ))}
                    <hr className='hr-tag'></hr>
                  </div>
                  <div>
                    <p classname='p-category' style={{ marginBottom: '3px', fontWeight: 'bold' }}>Website</p>
                    <h1>{data.website ? ("https://" + data.website) : ("Not Available")}</h1>
                    <hr className='hr-tag'></hr>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
          <Contact />
        </div>
      </div>
    </>
  );
}
