import React from 'react';
import { Box, Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const DesignCard = ({ title, count, gradient }) => {
    return (
        <Box
            sx={{
                backgroundColor: '#ececec',
                padding: '20px',
                borderRadius: '25px',
                textAlign: 'left',
                height: { xs: '240px', md: '280px' },
                width: { xs: '200px', md: '270px' },
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                cursor: 'pointer',
                transition: 'transform 0.2s ease-in-out', // Smooth transition effect
                '&:hover': {
                    transform: 'scale(1.1)', // Slightly enlarge the box on hover
                },
            }}
        >
            <div style={{ textAlign: 'end' }}>
                <Typography
                    variant="caption"
                    sx={{
                        letterSpacing: '0.17em',
                        marginBottom: '10px',
                        width: '190px',
                        backgroundColor: '#FFFFFF',
                        padding: '5px 12px',
                        borderRadius: '16px',
                    }}
                >
                    {count ?(count+" Courses"): ("No Courses")}
                </Typography>
            </div>

            <Typography
                variant="h4"
                sx={{
                    fontWeight: 'bold',
                    marginBottom: '10px',marginTop: '60px', width: '190px',minHeight: '70px',
                    width:{xs:'150px'},
                    fontSize: { xs: '27px', sm: '30px', md: '31px' },
                }}
            >
                {title}
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <Box
                    sx={{
                        height: '30px',
                        width: '100%',
                        background: gradient,
                        // borderRadius: '4px',
                        marginRight: '10px',
                        marginTop: '10px'
                    }}
                ></Box>
                <ArrowForwardIcon sx={{ color: 'black' }} />
            </Box>
        </Box>
    );
};

export default DesignCard;
