import React from 'react'
import Navbar from './Navbar';
import Contact from './Contact';
export default function Sample() {
  return (
    <>
            <div className="dup-body">
                <div className='dup-body-wrap'>
                    <Navbar title='SavvyPool' nav1="Home" nav2="List your institute" />
                    <hr className='hr'></hr>
                    <div className="policy" style={{ marginLeft: 10 }}>
                        <h1 className="privacy">Privacy policy</h1>
                        <section className="policies">
                            <h3 className="h3_policy">
                                Before using the platform’s services, please read the Privacy Policy
                                carefully. Your acceptance of the terms of use and our Privacy Policy, as
                                well as your consent and agreement to be legally bound by the same, are
                                signified by using this platform to obtain services. We committed to
                                protecting the privacy and personal information of users of our mobile
                                application, SavvyPool. This privacy statement describes how we gather,
                                utilise, disclose, and safeguard the data we receive from App users. By
                                accessing or using the App, you agree to the terms and practices described
                                in this Privacy Policy. Please avoid using this platform for any services
                                if you do not agree with the Privacy Policy. It is deemed that if you
                                continue to use the platform, you have given SavvyPool your unconditional
                                consent and agreement as set forth in section 43A and 72A of the
                                Information Technology Act, 2000.
                                <br />
                                <br />
                                a) Savvy Pool may need some personal information from users in the course
                                of and for the provision of its services, such as their name, email
                                address, contact information, permanent address, etc. In order to provide
                                seamless and uninterrupted services, Savvy Pool may be required to share
                                certain personal information, such as name and contact number, with its
                                registered business partners. Savvy Pool only utilizes such information to
                                verify and authenticate users' identities. However, Savvy Pool never uses
                                a user's personal information for commercial gain or divulges it to any
                                unrelated third parties.
                                <br />
                                <br />
                                b) For the purposes of this Privacy Policy, "you" or "User" shall mean any
                                natural or legal person, whether registered or not, who has agreed to use
                                the Platform and avail of Savvy Pool's services in accordance with the
                                terms of use, including Users whose accounts are about to expire.
                                <br />
                                <br />
                                c) You hereby consent to the collection, use, and disclosure of Your
                                information in the ways set forth in this Privacy Policy and acknowledge
                                that by using the Services, you agree to the practises and policies
                                specified in this Privacy Policy. You shouldn't use the Services if you
                                disagree with our Privacy Policy. You represent that you have the consent
                                of the person or entity using the Services on your behalf (such as your
                                kid) or on behalf of an organization (such as your company) to accept this
                                Privacy Policy.
                                <br />
                                <br />
                                d) This privacy policy complies with all applicable laws, rules, and
                                regulations that are currently in effect, including but not limited to the
                                Information Technology Act of 2000, the Information Technology (Reasonable
                                Security Practices and Procedures and Sensitive Personal Information)
                                Rules, 2011, and the Information Technology (Intermediaries Guidelines)
                                Rules, 2011, also known as the "SPI Rules" and the "IG Rules”.
                                <br />
                                <br />
                                e) The SavvyPool's collection of Personal Information and Sensitive
                                Personal Information ( here in after collectively referred to as
                                "Information"), as well as the goals, methods, and procedures involved in
                                gathering and using such Information, as well as the disclosure of such
                                Information, are governed by this Privacy Policy.
                            </h3>
                            <br />
                            <br />
                            <h3 className="h3_policy">
                                INFORMATION WE COLLECT:-
                                <br />
                                <br />
                                a) When you use the Platform, we gather and keep the personal data that
                                you voluntarily submit on occasion. Our main objective is to give you a
                                secure, effective, seamless, and personalized experience. This enables us
                                to tailor the Platform to your needs and give services and features that
                                will most likely satisfy them. This will make your experience safer and
                                simpler. More significantly, while doing so, we gather from you whatever
                                personal data we deem relevant for accomplishing this goal and offering
                                our services. We don't gather delicate personal data like biometric data,
                                bodily characteristics, or physiological status.
                                <br />
                                <br />
                                b) You must register with SavvyPool in order to access the Platform's
                                services. You are no longer anonymous to us after you provide us with your
                                personal information. We try to make it clear which fields are necessary
                                and which ones are optional. You may choose not to utilize a certain
                                service or feature on the Platform if you do not want to disclose
                                information. To use the Services, however, you must first create a
                                personal profile. We, our business partners, and other people with whom
                                you may freely choose to share your personal profile may examine the
                                information you provide therein in order to deliver seamless services,
                                assure authenticity, and prevent fraud and mischief.
                                <br />
                                <br />
                                c) To analyse the flow of our web pages, gauge the success of our
                                advertising campaigns, and advance confidence and safety, we employ data
                                gathering tools like "log data" and "cookies" on specific sites and in
                                specific areas of the Platform. The information or data that your browser
                                delivers to us as "Log Data" and the "Cookies" that are stored on your
                                computer's hard drive to help us provide our services are different. We
                                provide some services that can only be accessed by using a "cookie."
                                Additionally, we employ cookies so that you have to input your password
                                less frequently while logged in. Additionally, cookies might assist us in
                                delivering content that is customized to your interests. The majority of
                                cookies are "session cookies," which means that they are removed from your
                                hard drive at the conclusion of a session. If your browser allows it, you
                                may always choose to reject our cookies, but doing so may prevent you from
                                using some features of the Platform and force you to input your password
                                more frequently when logged in.
                                <br />
                                <br />
                                d) In addition, there is a chance that third-party "cookies" or other
                                similar technologies will appear on some Platform sites. Third-party
                                cookies are used by them; we have no control over them.
                                <br />
                                <br />
                                e) We will gather the data you give us if you decide to post messages on
                                our message boards or offer comments. As allowed by law, we keep your
                                information as long as it's required to resolve disputes, offer customer
                                assistance, and troubleshoot issues.
                                <br />
                                <br />
                                f) We may compile information about you into a file if you send us
                                personal communication, such as emails or letters, or if other users or
                                third parties provide us correspondence regarding your activity in
                                relation to the Platform.
                                <br />
                                <br />
                                g) When users engage with the Platform, we may gather non-personally
                                identifying data about them. Non-personal identification information may
                                include the name of the user's browser, the type of computer they are
                                using, and technical information about their means of connection to our
                                Site, including the browser type, the Internet service provider used, and
                                other similar information, like the Media Access Control (MAC) address,
                                screen resolution, operating system version, and type and version of their
                                internet browser. It may also include the date and time the user first
                                accessed the Platform or any of its components. If you visit the Platform
                                using a mobile device, we may collect comparable data, such as the kind
                                and identity of your device.
                                <br />
                                <br />
                                h) By using the Platform or by giving us your consent (where required by
                                law), you consent to the transfer of information to countries outside of
                                your country of residence, which may have different data protection laws
                                than in your country. Your personal information may be stored and
                                processed in any country where we have facilities or service providers.
                            </h3>
                            <br />
                            <br />
                            <h3 className="h3_policy">
                                USE OF PERSONAL DATA :-
                                <br />
                                <br />
                                a) We use personal data to fulfil your service requests, link you with our
                                business partners, and instantly confirm your geolocation. We use your
                                personal information to enforce our terms and conditions, resolve
                                disputes, troubleshoot issues, help promote a safe service, gauge consumer
                                interest in particular goods and services, personalize your experience,
                                detect errors, fraud, and other illegal activity, and as otherwise
                                disclosed to you at the time of collection.
                                <br />
                                <br />
                                b) We gather and analyse demographic and profile information regarding the
                                use of our website by our users in an attempt to continuously enhance our
                                product and service offerings. In order to further its goals and in the
                                interest of promoting open, consumer- and user-driven practices and
                                policies, SavvyPool reserves the right to disclose aggregate demographic
                                data of its users and members to advertisers, groups, associations,
                                governmental authorities, and others.
                                <br />
                                <br />
                                c) We do not divulge sensitive personal data about specific Users to
                                advertisers, merchants, or other third parties that promote their goods or
                                services on the platform, nor do we divulge such data to governmental
                                agencies or outside service providers.
                                <br />
                                <br />
                                d) In order to run our website and identify server issues, we recognise
                                your IP address and geolocation. Additionally, your IP address is used to
                                identify you and compile general demographic data about you.
                                <br />
                                <br />
                                e) We shall retain a record of the information you voluntarily supply us
                                via electronic mail so that we can reply to you. When you register on our
                                site or fill out a form, we are the only ones to receive information about
                                you. Additionally, you might be required to submit your name, email
                                address, or phone number while completing a form on our website. However,
                                you are welcome to browse our website anonymously. We retain the right to
                                call, SMS, email, or use WhatsApp to communicate with you about our goods
                                and services in the event that you have provided your personal information
                                and contact details—even if your phone number is set to DND.
                            </h3>
                            <br />
                            <br />
                            <h3 className="h3_policy">
                                SHARING OF INFORMATION WITH OUTSIDE PARTIES :-
                                <br />
                                <br />
                                1) Your privacy is a top priority for SavvyPool. To use the Platform, you
                                must acknowledge that we might occasionally have to release your personal
                                information to third parties.
                                <br />
                                <br />
                                2) SavvyPool reserves the right to provide legal, accounting, or other
                                advisors that provide professional services and/or who assist us in
                                training, quality monitoring, and quality enhancement of our services to
                                the user and/or business partners with call recordings of all phone calls
                                made between: [1] any User and SavvyPool; [2] any Business Partner and
                                SavvyPool; and [3] any User and Business Partner of SavvyPool. You hereby
                                provide SavvyPool your unreserved approval to distribute these call
                                recordings with third parties for such purposes. You also waive your right
                                to sue SavvyPool in connection with the same.
                                <br />
                                <br />
                                3) We could divulge personal data to our affiliates and corporate
                                partners. Unless you specifically opt out, these organizations and their
                                affiliates may market to you as a result of this sharing.
                                <br />
                                <br />
                                4) We could provide third parties access to personal data. In order to
                                give you access to our Services, to abide by legal obligations, to enforce
                                our User Agreement, to support our marketing and advertising efforts, or
                                to prevent, detect, mitigate, and investigate fraudulent or illegal
                                activities related to our services, we may need to provide you with this
                                disclosure. Without your express permission, we will never provide your
                                personal information to a third party for marketing or advertising.
                                <br />
                                <br />
                                5) If necessary to react to court orders, subpoenas, or other legal
                                process, we may be obligated by law or in the good faith conviction that
                                doing so is reasonably necessary to do so. In the good faith belief that
                                such disclosure is reasonably necessary to: enforce our Terms or Privacy
                                Policy; respond to claims that an advertisement, posting, or other content
                                violates the rights of a third party / User; or protect the rights,
                                property, or personal safety of our Users or the general public, we may
                                disclose personal information to law enforcement officers, third party
                                rights owners, or others.
                            </h3>
                            <br />
                            <br />
                            <h3 className="h3_policy">
                                SAFETY GUIDELINES :-
                                <br />
                                <br />
                                By highlighting the greatest user contributions, SavvyPool constantly
                                reaches out to a larger audience. However, in doing so, we will not permit
                                anything that, in our sole discretion, we believe might be potentially
                                dangerous, provocative, or humiliating to our Users, to be uploaded,
                                published, or put on the Platform or website. In order to ensure their
                                online and Platform safety, users should keep the following in mind.
                                <br />
                                <br />
                                a) Password security is the exclusive responsibility of the user. Nobody
                                in charge at SavvyPool will know your password. You should take
                                precautions to prevent unauthorised access to your password, computer, and
                                mobile device. When completed, remember to log out of the Platform. No
                                responsibility is assumed by SavvyPool for any unauthorized use of your
                                account or password. SavvyPool must be notified right away if you believe
                                there has been any unauthorized use of your account by sending an email to{" "}
                                <a href="mailto:admin.sp@savvypool.com">admin.sp@savvypool.com</a>.
                                <br />
                                <br />
                                b) Each User is liable for the security of their personal data. Users are
                                strongly advised from posting their home addresses, email addresses, phone
                                numbers, or other personally identifiable information online for public
                                use.
                                <br />
                                <br />
                                c) All Users should be aware that any images they publish to the Platform
                                may be downloaded as anybody with access to a computer screen has the
                                ability to save any image they see. Technically, there is no way to stop
                                this, hence SavvyPool is not accountable in these situations. Please do
                                not post your photographs if you are concerned about someone else viewing
                                or storing them. You may, at your option, send our business partner a
                                photo directly, and not through the Platform, if they request one as part
                                of any contact between you and the partner.
                                <br />
                                <br />
                                d) Do not advertise pyramid schemes, distribute chain letters, or solicit
                                users to work for any network marketing or multilevel marketing companies.
                                <br />
                                <br />
                                e) You may not, whether personally or otherwise, invite users to join a
                                forum or chat group on another website.
                                <br />
                                <br />
                                f) All Users are cautioned that everything they say or do online might
                                result in legal repercussions. Particularly, Users may be held accountable
                                for any threatening remarks, false assertions, or other unlawful and
                                fraudulent claims they make.
                            </h3>
                            <br />
                            <br />
                            <h3 className="h3_policy">
                                MISCELLANEOUS
                                <br />
                                <br />
                                a) CHILDREN’S PRIVACY :-
                                <br />
                                <br />
                                Any individual over the age of 12 may use our services, however those
                                under the age of 18 must have a parent or legal guardian present during
                                the whole process or session. Without the permission of their parents or
                                legal guardians, we never intentionally collect personally identifiable
                                information from children or other users under the age of 18. If we learn
                                that a child under the age of 18 has given us personal information, we
                                will promptly tell them and ask them to either get their parent's or legal
                                guardian's permission or remove the information. After the predetermined
                                amount of time, we remove the data from our servers if the user disregards
                                the instruction. Please get in touch with us if you're a parent or
                                guardian and you know that your child has given us personal information so
                                that we can take the appropriate measures.
                                <br />
                                <br />
                                b) LINKS TO OTHER WEBSITES :-
                                <br />
                                <br />
                                Links to other websites that may gather personally identifiable
                                information about you are available on our website. Savvy Pool highly
                                suggests that you study or carefully read over the privacy policy of such
                                websites before continuing to visit them since Savvy Pool is not
                                accountable for the privacy practises or the content of such third party
                                websites.
                                <br />
                                <br />
                                c) SECURITY PRECAUTIONS :-
                                <br />
                                <br />
                                Strict security controls are in place on our platform and website to
                                prevent the loss, misuse, and modification of the data that is in our
                                care. We provide the usage of a secure server whenever you modify or
                                access the information associated with your account. Once we have your
                                information, we follow strong security procedures to keep it safe from
                                unauthorized access. To safeguard the personal data we handle, we take
                                appropriate administrative, technological, and organizational steps.
                                Unfortunately, there is no way to ensure that a data storage system or
                                Internet-based data transfer will be 100% safe. If you have cause to
                                suspect that dealings with us are no longer secure (for instance, if you
                                think the safety of any accounts you may have with us has been
                                jeopardized), please let us know immediately. please immediately notify us
                                of the problem by contacting us or emailing us at{" "}
                                <a href="mailto:admin.sp@savvypool.com">admin.sp@savvypool.com</a>
                                <br />
                                <br />
                                d) COMMERCIALS ON SAVVYPOOL’S SUB-DOMAINS :-
                                <br />
                                <br />
                                When you visit our website or platform, we may employ the services of
                                third-party advertising firms to show ads. In order to show you adverts
                                for products and services you might be interested in, these businesses may
                                utilise information about your visits to this and other websites (but not
                                your name, address, email address, or phone number).
                                <br />
                                <br />
                                e) YOUR CONSENT :-
                                <br />
                                <br />
                                By using the Platform and/or submitting your information, you consent to
                                the collection and use of the information You disclose on the Website or
                                Platform in accordance with this Privacy Policy, including but not limited
                                to Your consent for disclosing your information in accordance with this
                                Privacy Policy.
                                <br />
                                <br />
                                f) TO THIS PRIVACY POLICY :-
                                <br />
                                <br />
                                As and when there is any changes amendment to this policy, you will be
                                asked to accept the amended policy if you wish to continue to use the
                                Platform and only after your acceptance you could continue to use the
                                platform.
                            </h3>
                            <br />
                            <br />
                            <h3 className="h3_policy">
                                CHANGES TO THE PRIVACY POLICY :-
                                <br />
                                <br />
                                We might occasionally update our privacy statement. Any significant
                                changes will be communicated to you by posting the updated Privacy Policy
                                on our website or through the App. You agree to the revised Privacy Policy
                                by continuing to use the App after such updates.
                            </h3>
                            <br />
                            <br />
                            <h3 className="h3_policy">
                                CONTACT US :-
                                <br />
                                <br />
                                Please get in touch with us at [contact information] if you have any
                                queries, concerns, or recommendations regarding our privacy statement or
                                our privacy practices. Please be aware that this Privacy Policy only
                                applies to the App and does not apply to any websites, services, or
                                applications operated by third parties to which the App may provide links.
                                Before sending any personal information to any third parties, we urge you
                                to study their privacy policies.
                            </h3>
                        </section>
                    </div>
                    <Contact />
                </div>
            </div>
        </>
  )
}
