import React from 'react'
import Navbar from './Navbar';
import Contact from './Contact';
export default function Terms() {
    return (
        <>
            <div className="dup-body">
                <div className='dup-body-wrap'>
                    <Navbar title='SavvyPool' nav1="Home" nav2="List your institute" />
                    <hr className='hr'></hr>
                    <div className="policy" style={{ marginLeft: 10 }}>
                        <h1 className="privacy">TERMS OF USE</h1>
                        <section className="policies">
                            <h3 className="h3_policy">
                                Greetings from SavvyPool! It costs nothing to register and use the
                                website/application and its services. However, we do ask that you follow
                                by all the rules listed below.
                                <br />
                                <br />
                                <strong>1. Acceptance of terms</strong>
                                <br />
                                <br />
                                1.1. You agree to and will be bound by the terms of this agreement,
                                including the details provided below, by using or otherwise accessing the
                                services and/or by enrolling with SavvyPool. You should not use the
                                services if you do not agree with this agreement. Additionally, SavvyPool
                                has full discretion over whether you get access to any of its platforms.
                                <br />
                                <br />
                                1.2 Any service given by SavvyPool may be added, substituted, altered, or
                                terminated at any time. Any updates will be noted in the terms and
                                conditions as they are made, and it is your duty to remain up to date on
                                them. You will be deemed to have read, understood, and accepted to be
                                bound by the Terms of Use or other applicable agreement document, as
                                informed if you use the site or any of the services following such
                                posting. After such an announcement, it will be presumed that you have
                                continued to use the website or any of the services.
                                <br />
                                <br />
                                1.3. It is solely your responsibility to stay informed about changes to
                                the Terms of Use and Privacy Policy. If you decide at any time not to
                                accept these terms of use, including any changes made later on, you must
                                stop using the Services and using SavvyPool Platforms.
                                <br />
                                <br />
                                1.4. You agree that in the event that any of the services, or a portion
                                thereof, are suspended or cancelled, SavvyPool won't be liable to you or
                                anyone else. This privacy statement and any updates to it govern the
                                collection, use, and disclosure of your personal information while using
                                our services as a visitor or member.
                            </h3>
                            <br />
                            <br />
                            <h3 className="h3_policy">
                                <strong>2. Visitors and Members</strong>
                                <br />
                                <br />
                                Once you have used our services, we may continue to collect information
                                about you that can be considered private or personal in order to improve
                                them. Depending on the nature and quantity of data obtained, you will
                                either be a visitor or a member. If you are not prepared to give such
                                information, do not use the SavvyPool platforms or services.
                                <br />
                                <br />
                                <strong>3. Eligibility of user</strong>
                                <br />
                                <br />
                                To register on our platform and use our services, you must be of legal
                                age. By using SavvyPool Platforms and/or the Services, you represent and
                                warrant that you have the right, authorization, and ability to engage in
                                these Terms of Use and to adhere to all of the terms and conditions set
                                out herein, even if you do not meet the requirements set forth in these
                                Terms.
                                <br />
                                <br />
                                3.1. Despite what was previously mentioned, anybody beyond the age of 12
                                may register on the platform, with the requirement that the registration
                                be approved by the person's legal guardian.
                                <br />
                                <br />
                                3.2 If you are creating an account or using the services on behalf of a
                                person or organization other than yourself, you guarantee that you have
                                the necessary authority to accept this agreement on their behalf.
                            </h3>
                            <br />
                            <br />
                            <h3 className="h3_policy">
                                <strong>4. Compliance with Law</strong>
                                <br />
                                <br />
                                The Indian Contract Act, 1872, the Information Technology Act, 2000, the
                                Information Technology (Reasonable Security Practices and Procedures and
                                Sensitive Personal Information) Rules, 2011 (hereinafter referred to as
                                the "SPI Rules"), and the Information Technology (Intermediaries
                                Guidelines) Rules, 2011 (hereinafter referred to as the "Intermediaries
                                Guidelines Rules"), among others, are all complied with by this Agreement.
                                <br />
                                <br />
                                <strong>5. Definitions</strong>
                                <br />
                                <br />
                                5.1. “Applicable Laws” means any domestic or foreign law, rule, statute,
                                subordinate legislation, regulation, by-law, order, ordinance, protocol,
                                code, guideline, treaty, policy, notice, direction or judicial, arbitral,
                                administrative, ministerial or departmental judgment, award, decree,
                                treaty, directive, or other requirement or guideline published or in force
                                at any time during the Term which applies to or is otherwise intended to
                                govern or regulate any Person (including any party), property,
                                transaction, activity, event or other matter, including any rule, order,
                                judgment, directive or other requirement or guideline issued by any
                                Governmental or Regulatory Authority.
                                <br />
                                <br />
                                5.2 “User” refers to any individual, institute, business, or other
                                organization that has registered on Savvypool and interact with website,
                                online services, app or platform in any way.
                                <br />
                                <br />
                                5.3. "Services" refers to all services offered on any platform operated by
                                SavvyPool. The Services offered by SavvyPool are flexible and subject to
                                change at SavvyPool' sole discretion;
                                <br />
                                <br />
                                5.4. "Our Content" refers to any text, information, data, graphic,
                                picture, photograph, video, audio, recommendation, instruction, and other
                                materials made accessible through the Services or on any SavvyPool
                                Platform.
                                <br />
                                <br />
                                5.5. "User Content" refers to both the materials that SavvyPool posts on
                                the User's behalf on SavvyPool Platforms and the data that the User makes
                                accessible to other Users on the Site.
                                <br />
                                <br />
                                5.6. "Posted Information" refers to all information posted by a user
                                personally or on their profiles to participate in the other interactive
                                and community features of the site, including but not limited to inquiries
                                about educational courses, information about educational institutions,
                                information about prospective students, career information, information
                                about upcoming jobs, and other such content.
                                <br />
                                <br />
                                <strong>6. Sharing of information</strong>
                                <br />
                                <br />
                                Our services enable communications and information sharing in a variety of
                                methods, including your profile, articles, group postings, messages, links
                                to news items, etc. Others (including those outside of the Services) may
                                view the information and material that you publish or upload. We are not
                                required to post any material or content on our service, and we reserve
                                the right to delete it at any time with or without prior notice.
                                <br />
                                <br />
                            </h3>
                            <h3 className="h3_policy">
                                <strong>7. Your license to SavvyPool</strong>
                                <br />
                                <br />
                                7.1 In your relationship with SavvyPool, the data and material you submit
                                or publish on the SavvyPool Platforms are your property, and you are only
                                providing SavvyPool the following non-exclusive license:
                                <br />
                                <br />
                                1. Right to use the data and material you provide through our services and
                                those of third parties on a global, transferrable, and sublicensable basis
                                without further permission from you, without your knowledge or that of
                                others, and without restriction. The following limitations apply to these
                                rights:
                                <br />
                                <br />
                                2. You can terminate this license for a specific piece of content by
                                removing it from the Services or, more generally, by closing your account,
                                with the exception of (a) any instances in which you shared the content as
                                part of the Service with third parties and they copied, reshared, or
                                stored it, and (b) while it takes a reasonable amount of time to remove
                                the content from backup and other systems.
                                <br />
                                <br />
                                3. Without your separate approval, we will not use your material
                                (including sponsored content) in ads for goods and services provided by
                                third parties to others. As stated in the Privacy Policy, we reserve the
                                right to offer advertising near your content and information without
                                charging you or anyone else, and these ads may display and contain
                                information about your social media activity. Depending on your
                                preferences, we may use your name or photo, along with the fact that you
                                used a service feature, to advertise that feature within our services.
                                <br />
                                <br />
                                4, We reserve the right to edit and format your material (including adding
                                or deleting information, changing the size, style, or file type), but we
                                won't affect the meaning of what you say.
                                <br />
                                <br />
                                5. With the use of information we obtained from third parties, we use your
                                data for contents on our platform and website. If you don't want to be
                                featured in SavvyPool, you can amend or delete your content from any of
                                our sites.
                                <br />
                                <br />
                                7.2 You agree to our accessing, storing, processing, and using the
                                information and personal data you provide in accordance with the Privacy
                                Policies guidelines.
                                <br />
                                <br />
                                7.3 By sending recommendations or other feedback about our services, you
                                agree that SavvyPool may use and disseminate that input for any reason
                                without paying you any money. However, SavvyPool is not required to do so.
                                7.4 You agree to only offer content or information that complies with
                                legal requirements and respects everyone else's rights, including theirs
                                to intellectual property. You also agree that the information on your
                                profile will be accurate. In some nations, we can be forced by law to
                                delete specific data or material.
                                <br />
                                <br />
                                7.5. We provide suggestions for connections, materials, and features that
                                you might find valuable based on the information and data you supply and
                                that we already have about members.
                                <br />
                                <br />
                                1. When using the services, you can come across content or information
                                that is incorrect, lacking, delayed, deceptive, unlawful, objectionable,
                                or otherwise harmful. In general, SavvyPool does not examine content
                                submitted by our members or by other parties. You acknowledge that we are
                                not liable for the content or information of third parties (including
                                other Members). You accept that we are not liable for any misuse of our
                                services that we are unable to always prevent. When we notify connections
                                and followers that you or your organization was referenced in the press,
                                you also understand the possibility that you or your business might be
                                inadvertently linked to information about other people. Regarding this
                                feature, members have options.
                                <br />
                                <br />
                                2. Members selling their services and members looking for services may be
                                connected using SavvyPool platforms. Although SavvyPool cannot be held
                                liable for the provision, delivery, or acquisition of these services and
                                does not support any specific member's supplied services, each user must
                                be responsible for utilizing any services provided by other users.
                                <br />
                                <br />
                                3. Similarly, SavvyPool may assist you in signing up for and/or attending
                                events hosted by members or users, as well as connecting with other
                                members who are present. You acknowledge and accept that (1) SavvyPool is
                                not liable for any Members' or other attendees' behavior at such events,
                                (2) SavvyPool does not support any specific event listed on our Services,
                                (3) SavvyPool does not review and/or vet any of these events, and (4) you
                                will abide by the terms and conditions that apply to such events.
                                <br />
                                <br />
                                <strong>8. Responsibilities of the user</strong>
                                <br />
                                <br />
                                8.1 The User is prohibited from hosting, displaying, uploading,
                                downloading, editing, publishing, transmitting, updating, or sharing any
                                information that —
                                <br />
                                <br />
                                8.1.1. belongs to another individual and over which the User has no
                                authority;
                                <br />
                                <br />
                                8.1.2. is very dangerous, harassing, blasphemous, defamatory, obscene,
                                sexually explicit, pedophilic, libelous, invading another person's
                                privacy, undesirable due to race or ethnicity, insulting, linked to or
                                supporting money laundering or gambling, or otherwise illegal in any way;
                                <br />
                                <br />
                                8.1.3. hurt infants in any form;
                                <br />
                                <br />
                                8.1.4. violates any intellectual property rights (patents, trademarks,
                                copyrights, etc).
                                <br />
                                <br />
                                8.1.5. breaches any already applicable law;
                                <br />
                                <br />
                                8.1.6. misleading the addressee about the source of the communications or
                                sending any material that is blatantly insulting or ominous in character;
                                <br />
                                <br />
                                8.1.7. impersonate another person
                                <br />
                                <br />
                                8.1.8. comprises computer code, files, or programmes that have the ability
                                to interrupt, delete, or restrict the usage of any computer resource;
                                <br />
                                <br />
                                8.1.9. threatens India's unity, integrity, defense, security, or
                                sovereignty; cordial relations with other countries; or public order;
                                incites criminal activity; obstructs the investigation of criminal
                                activity; or denigrates any other country
                                <br />
                                <br />
                                8.1.10. violates or tries to violate the SavvyPool Platforms' security
                                integrity
                                <br />
                                <br />
                                8.2 The user must not use any of the SavvyPool platforms for any reason
                                other than those specified.
                                <br />
                                <br />
                                8.3 The user shall not provide any information that is incomplete,
                                incorrect, or inaccurate on purpose.
                                <br />
                                <br />
                                8.4 Users shall not communicate with other users in an uninvited manner.
                                <br />
                                <br />
                                8.5 The user must not abuse the site’s content.
                                <br />
                                <br />
                                <strong>9. Intellectual Property Rights</strong>
                                <br />
                                <br />
                                All of SavvyPool's intellectual property rights in the services are
                                reserved. The services may contain trademarks and logos that belong to
                                their respective owners.
                                <br />
                                <br />
                                <strong>10. Indemnity</strong>
                                <br />
                                <br />
                                10.1 The user agrees to hold SavvyPool, its affiliates, officers,
                                directors, employees, consultants, licensors, agents, and representatives
                                harmless from any and all third-party claims, losses, liability, damages,
                                and/or costs (including reasonable attorney fees and costs) resulting from
                                the user's access to or use of the service, violation of this Agreement,
                                or infringement of any intellectual property or other right by the user or
                                any other user of his/her/its account. In addition to your commitments
                                stated above, you undertake to give SavvyPool reasonable help in defending
                                against any such claim, loss, liability, damage, or cost. SavvyPool will
                                quickly notify you of any such claim, loss, liability, or demand.
                                <br />
                                <br />
                                10.2 Under no circumstances, including but not limited to negligence,
                                shall SavvyPool or any of its directors, officers, employees, agents,
                                content, or service providers be liable for any direct, indirect, special,
                                incidental, consequential, exemplary, or punitive damages arising out of,
                                or in any way connected with, the use of, or the inability to use, the
                                Site or the content, materials, and features related thereto, the
                                Services, or the user's provision of information via the us.
                                <br />
                                <br />
                                10.3 SavvyPool's total aggregate liability to a User for all losses,
                                damages, and causes of action (whether in contract or tort, including, but
                                not limited to, negligence or otherwise) resulting from this Agreement or
                                a User's use of the SavvyPool Platform or Services shall in no event
                                exceed Rs. 1000/- (Rupees One Thousand Only).
                                <br />
                                <br />
                                <strong>11. Assignment</strong>
                                <br />
                                <br />
                                11.1 SavvyPool may transfer this agreement at any time as part of the sale
                                to, merger with, or other transfer of our business to another party,
                                including without limitation to any parent,
                                <br />
                                <br />
                                11.2 Any attempt by you to do so in violation of this provision shall be
                                invalid, and you are not permitted to assign, transfer, or sublicense this
                                Agreement to anyone else. subsidiary, or related firm.
                                <br />
                                <br />
                                <strong>12. Waiver</strong>
                                <br />
                                <br />
                                In no event shall SavvyPool's relaxation, forbearance, or delay in
                                enforcing or exercising any of the terms of this Agreement prejudice,
                                affect, or restrict SavvyPool's rights, nor shall SavvyPool's waiver of
                                any breach hereof operate as a waiver of any subsequent or continuing
                                breach of any term or condition of this Agreement, unless in writing. .
                                <br />
                                <br />
                                <strong>13. Severability</strong>
                                <br />
                                <br />
                                The remainder of the terms, provisions, covenants, and restrictions set
                                forth herein shall remain in full force and effect and shall not be
                                affected in any way if any provision, covenant, or restriction contained
                                in this Agreement is determined by any judicial or other competent
                                authority to be invalid, void, voidable, illegal, or otherwise
                                unenforceable, The parties hereto are free to change the provision,
                                covenant, or limitation in a way that is fair and allows them to fulfil
                                their intentions without breaking the law, or, at the parties' discretion,
                                they may decide to remove it entirely from this Agreement. The remaining
                                provisions of this agreement will remain in full force and effect unless
                                the Parties decide that the effect of such a declaration is to defeat the
                                Parties' original intention. Such a provision shall be deemed to have
                                existed as of the date of execution of this agreement.
                                <br />
                                <br />
                                14. Entire Agreement All previous agreements, understandings, and
                                undertakings regarding confidential information, if any, are superseded by
                                this agreement, which contains the full agreement and understanding
                                between the parties in this rega
                                <strong> 15. Supplementary Agreement</strong>
                                <br />
                                <br />
                                The parties agree that this agreement should be read in conjunction with
                                the privacy policy and any other agreements they have made. The contents
                                of this agreement shall prevail and take precedence over all conflicting
                                provisions of any other or supplemental agreements in the event of a
                                dispute.
                                <br />
                                <br />
                                <strong>16. Headings</strong>
                                <br />
                                <br />
                                The headlines in this agreement are included for reference and convenience
                                and should not be used as the only guide when interpreting the clauses or
                                sections to which they pertain. They do not constitute a part of this
                                agreement and in no way affect the intent, meaning, or range of this
                                agreement or any of its terms or conditions.
                                <br />
                                <br />
                                <strong>17. Arbitration</strong>
                                <br />
                                <br />
                                17.1 Any disagreements or disputes that develop between the parties over
                                any provision of this agreement may be brought before a single arbitrator.
                                The arbitrator's decision is final and enforceable against the parties.
                                SavvyPool is responsible for appointing the arbitrator. Bengaluru will
                                serve as the arbitration's location and site. The arbitration will be
                                conducted in English. The Arbitration and Conciliation Act, 1996's
                                provisions shall be applied to the arbitration procedures. The sole
                                arbitrator will also decide on any disputes involving the execution or
                                <br />
                                <br />
                                17.2 SavvyPool has the right to choose whether to temporarily or
                                permanently disable a user's profile after termination. The user may be
                                permitted to re-register or to use the same account at SavvyPool's
                                discretion. legality of this agreement and questions of jurisdiction.
                                <br />
                                <br />
                                <strong>18. Jurisdiction and disputes</strong>
                                <br />
                                <br />
                                The laws of India shall govern this agreement in all respects, and those
                                laws shall be followed in interpreting it. In relation to this Agreement,
                                your use of the website, the services, or the information to which it
                                provides access, Bengaluru courts shall have sole jurisdiction. Users use
                                the Services and the Site at their own risk and are responsible for
                                adhering to any local laws that may be in force where they use the
                                Services or the Site.
                                <br />
                                <br />
                                <strong>19. Grievance Officer</strong>
                                <br />
                                <br />
                                Any complaints concerning such information must be sent in writing or by
                                email signed with an electronic signature to SavvyPool at
                                info@SavvyPool.com by the aggrieved party.
                                <br />
                                <br />
                                Who is responsible for what I see and do on the Services?
                                <br />
                                <br />
                                You access all such information and content at your own risk, and we are
                                not responsible for any errors or omissions in that information or content
                                or for any losses or damages you may incur as a result of using it. Any
                                information or content that is publicly posted or privately transmitted
                                through the Services is the sole responsibility of the person from whom
                                such content originated. You hereby release us from all liability for you
                                having acquired or not acquired Content through the Services. We cannot
                                control how you may interpret and use the Content, and we are under no
                                obligation to take any action regarding any actions you may take as a
                                result of having been exposed to the Content. When utilizing the Services,
                                you may interact with any user, but we are unable to confirm their
                                identity, and we have no control over who has access to them. You are
                                accountable for all Content you publish or otherwise make available
                                through the Services, and you affirm and warrant that you are fully
                                authorized to publish it in the manner in which you do. You agree to keep
                                all registration information up to date and correct. You are accountable
                                for all of your actions related to the services. Links or connections to
                                third-party websites or services that are not within SavvyPool's control
                                or ownership may be found on the Services. For example, SavvyPool may
                                display advertising and promotions from third parties on the Services, or
                                may do so in other ways like supplying details about or connections to
                                third-party goods or services. Regarding any third-party products,
                                services, promotions, or sellers, SavvyPool does not recommend them or
                                provide any guarantees. You acknowledge that there are risks involved when
                                using third-party websites or services and that SavvyPool is not liable
                                for those risks. We advise you to read the terms and conditions and
                                privacy policies of any third-party website or service you visit or use
                                before proceeding when you leave the Services. You agree that accessing
                                third-party websites or services has risks and that SavvyPool is not
                                responsible for any risks. When you leave the Services, we suggest you to
                                read the terms and conditions and privacy policies of every third-party
                                website or service you visit or use before continuing.
                                <br />
                                <br />
                                The services of SavvyPool will they ever change? The services may change
                                over time since we're constantly working to enhance them. Any portion of
                                the Services may be suspended or discontinued, or we may add new features,
                                place restrictions on certain features, or limit access to any or all of
                                the Services. When we make a significant change to the services that will
                                negatively impact you, we'll strive to notify you, but sometimes it just
                                isn't feasible. In a similar vein, we retain the right, at any time, in
                                our sole discretion, and without prior notice, to delete any content from
                                the Services for any reason (including, but not limited to, if someone
                                claims you provided that content in violation of these Terms).
                            </h3>
                        </section>
                    </div>
                    <Contact />
                </div>
            </div>
        </>
    )
}
