import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import '../App.css';
import Cards from '../components/Cards';
import { motion } from 'framer-motion';
import { fetchPrograms } from '../services/CommonApi';
import { CircularProgress } from '@mui/material';

export default function DataCard() {
  const location = useLocation();
  const { category_id,count } = location.state || '';
  const { shortname } = useParams();
  const navigate = useNavigate();
  const [cardData, setCardData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [empty,setEmpty]=useState(false)

  //********************** */

  //first data fetching code
  const [page, setPage] = useState(1);
  const totalPagesRef = useRef(1);
  const isFetchingRef = useRef(false); // To prevent multiple API calls

  const formattedShortname = shortname
    .split('-') 
    .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
    .join(' ');

  const fetchfilter = async () => {
    try {
      setLoading(true)
      const params = { category_id, offset: page };
      const response = await fetchPrograms(params);
      totalPagesRef.current = response.total_pages;
      if (response.courses && response.courses.length > 0) {
        setCardData(prevData => [...prevData, ...response.courses]);
        setLoading(false)
      } else if (page === 1) {
        setCardData([]);
        setLoading(false)
      }
      isFetchingRef.current = false;
    } catch (error) {
      console.error('Error fetching data', error);
      isFetchingRef.current = false;
    }
  };

  useEffect(() => {
    fetchfilter();
  }, [page, category_id]);

  const handleScroll = () => {
    if (isFetchingRef.current) return;

    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = document.documentElement.clientHeight;

    if (scrollTop + clientHeight >= scrollHeight - 100) {
      if (page < totalPagesRef.current) {
        isFetchingRef.current = true;
        setPage(prevPage => prevPage + 1);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [page]);

  //********************** */
  return (
    <>
      <div className='course-meter'>
        <h1 className='Container-title'>{formattedShortname}</h1>
        <p>( Courses found : {count || 'Not Found'} )</p>
      </div>
      <div className='card-container'>
        {cardData && cardData.length > 0 && cardData.map((cards, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: 0.1 }}
          >
            <div key={cards.course_id} onClick={() => {
              // navigate(`/shortcourse/course-details/institute/${cards.institution_name}`, { state: { institute_id: cards.institution_id } })
              navigate(`/shortcourse/course-details/${cards.course_id}`,{state:{course_id:cards.course_id}});
            }}>
              <Cards
                logo={cards.logo_url}
                InstitutionName={cards.institution_name}
                CourseShortName={cards.course_name}
                CourseName={cards.course_name}
                CourseFee={cards.course_fee}
                CourseFeeType={cards.course_fee_type}
                CourseDurationy={cards.course_duration_year}
                CourseDurationm={cards.course_duration_month}
                CourseId={cards.course_id}
                institutionid={cards.institution_id}
              />
            </div>
          </motion.div>
        ))}
        {/* Circular Progress while loading next page */}
        {loading && (
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', width: '100%' }}>
              <CircularProgress />
            </div>
          )}
      </div>
    </>
  )
}
