import { useEffect, useRef, useState } from 'react';
import Apicard from './viewinsitutionCard';
import Navbar from './Navbar';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { allinsitutes} from '../services/CommonApi';
import { CircularProgress} from '@mui/material';

import Contact from './Contact';

const Viewinstitute = () => {

  const navigate = useNavigate()

  const [page, setPage] = useState(1);
  const [empty, setEmpty] = useState(false);
  const [loading, setLoading] = useState(false);

  //**********************************************************88 */
  //first data fetching code
  const [filteredData, setFilteredData] = useState([]);
  const totalPagesRef = useRef(1);
  const isFetchingRef = useRef(false); // To prevent multiple API calls

  const fetchfilter = async () => {
    try {
      setLoading(true);
      const params = { limit:10, page: page };
      const response = await allinsitutes(params);
      
      totalPagesRef.current = response.total_pages;
      if (response.institute && response.institute.length > 0) {
        setFilteredData(prevData => [...prevData, ...response.institute]);
        setLoading(false);
        setEmpty(false)
      } else{
        setEmpty(true)
        setLoading(false);
      }
      isFetchingRef.current = false;
    } catch (error) {
      console.error('Error fetching data', error);
      isFetchingRef.current = false;
    }
  };

  useEffect(() => {
    fetchfilter();
  }, [page]);

  const handleScroll = () => {
    if (isFetchingRef.current) return;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = document.documentElement.clientHeight;

    if (scrollTop + clientHeight >= scrollHeight - 100) {
      if (page < totalPagesRef.current) {
        isFetchingRef.current = true;
        setPage(prevPage => prevPage + 1);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [page]);


  //************************************************************* */

  return (
    <div className='dup-body-wrap'>
      <Navbar title='SavvyPool' nav1='Home' nav2='List your institute' />
      <hr className='hr'></hr>
      <div className='university-course-container' style={{display:"flow"}}>
        <div className='university-course'>
          {filteredData.length > 0 ? (
            filteredData.map((university, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3, delay: 0.1 }}
              >
                <div key={university.course_id} onClick={() => {
                  // navigate(`/shortcourse/course-details/institute/${university.institution_name}`, { state: { institute_id: university.institution_id } })
                  navigate(`/shortcourse/course-details/institute/${university.institution_id}`, { state: { institute_id: university.institution_id} })
                }}>
                  <Apicard
                    Institutionname={university.university_name}
                    logo={university.logo_url}
                    CourseName={university.about_us}
                  />

                </div>
              </motion.div>

            ))
          ) : (
            <div className='course-none'>
              {!empty ? (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                <CircularProgress />
              </div>) :
                (<><img src='/img/error.svg' alt='error svg'></img>
                  <p>The course you were looking for</p>
                  <h1>Was not found.</h1></>)
              }
            </div>
          )}
          {/* Circular Progress while loading next page */}
          {loading && (
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', width: '100%' }}>
              <CircularProgress />
            </div>
          )}
        </div>
      </div>
      <Contact />
    </div>
  );
};

export default Viewinstitute;