import React, { useEffect, useState } from 'react'
import Banner from '../components/Banner';
import Card from '../components/Popularcategory';
import Contact from '../components/Contact';
import Features from '../components/Features';
import Navbar from '../components/Navbar';
import Testimonial from '../components/Testimonial';
import { allcategory } from '../services/CommonApi';
export default function Home() {
  const [categories, setCategories] = useState([]);

  // Fetch categories from the API
  const fetchCategories = async () => {
    const response = await allcategory({ data: true });
    
    if (response.sub_categories) {
      setCategories(response.sub_categories);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);
  return (
    <>
      {/* <Layout> */}
        <div className="dup-body">
          <div className='dup-body-wrap'>
            <Navbar title='SavvyPool' nav1="Home" nav2="List your institute" categories={categories}/>
            <hr className='hr'></hr>
            <Banner />
            <Card categories={categories}/>
            <Features />
            <Testimonial />
            <Contact />
          </div>
        </div>
      {/* </Layout> */}
    </>
  )
}
