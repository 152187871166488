import React, { createContext, useContext, useState } from 'react';

const FilterContext = createContext();

export const useFilter = () => useContext(FilterContext);

export const FilterProvider = ({ children }) => {
  const [category_id, setCategoryFilter] = useState(0);
  const [institution_id, setInstitutionFilter] = useState('');
  const [location, setLocationFilter] = useState('');
  const [page, setPage] = useState(1);
  const [selectedStateName, setselectedStateName] = useState('')

  const value = {
    category_id,
    selectedStateName, 
    setselectedStateName,
    setCategoryFilter,
    institution_id,
    setInstitutionFilter,
    location,
    setLocationFilter,
    page,
    setPage,
  };

  return <FilterContext.Provider value={value}>{children}</FilterContext.Provider>;
};
