import React from 'react';
import DesignCard from './popularcategorycarddesign.js';
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom';
const BannerH1 = styled.h1`
  font-size: 35px;
  font-weight: 800; 
  margin: 15px;
  line-height: 40px;
  @media (max-width: 600px) {
    font-size: 25px;
    line-height: 50px;
    padding: 0px 10px;
  }
`;
const Category = (categories) => {
  // Filter the categories to include only those with is_primary set to true
  const filteredCategories = categories.categories.filter(category => category.is_primary);
  const generateGradient = (index) => {
    const colors = [
      ['#A6C1EE', '#4D9FEC', '#1A73E8'],
      ['#FF9A9E', '#FF6A88', '#FF3D71'],
      ['#A1FFCE', '#C8FFC8', '#FAFFD1'],
      ['#A6C1EE', '#4D9FEC', '#1A73E8'],
      ['#FF5858', '#FFB199', '#1A73E8']
    ];
    const selectedColors = colors[index % colors.length];

    return `linear-gradient(to right, ${selectedColors.join(', ')})`;
  };

  return (
    <div >
      <BannerH1>Pupular Categories</BannerH1>
      <div style={{
        display: 'flex', gap: '30px', alignItems: 'center',
        overflowX: 'auto', padding: '20px', scrollbarWidth: 'none',
      }}>
        {filteredCategories.map((category, index) => (
          <Link key={category.category_id} to={`/shortcourse/${category.category_name.toLowerCase().replace(/ /g, '-')}`} state={{ category_id: category.category_id,count:category.course_count}}>
            <div key={category.category_id}>
              <DesignCard title={category.category_name} count={category.course_count} gradient={generateGradient(index)} />
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Category;
