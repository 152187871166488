import React, { useState } from 'react';
import styled, { css } from 'styled-components'
// import VideoComponent from './VideoComponent';
import { Link, useNavigate } from 'react-router-dom';
import SearchBar from './SearchBar';
import { IconButton, InputBase } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { allinsitutes } from '../services/CommonApi';
const BannerH1 = styled.h1`
  font-size: 30px;
  font-weight: 700; 
  margin-bottom: 0px;
  margin-right: -4px;
  line-height: 55px;
  @media (max-width: 600px) {
    font-size: 25px;
    line-height: 50px;
    padding: 0px 10px;
  }
`;
const BannerP = styled.p`
font-size: 30px;
// font-weight: 700; 
margin-bottom: 0px;
margin-right: -4px;
line-height: 55px;
@media (max-width: 600px) {
  font-size: 25px;
  padding: 0px 10px;
}
`
const buttonStyles = css`
  line-height: 42px;
  border-radius: 10px;
  color: #222222;
  display: inline-block;
  font-weight: 500;
  position: relative;
  border: 1px solid rgba(255, 201, 0, 0.8);
  background: #ffffff;
  transition: transform 0.2s, box-shadow 0.2s;
  padding: 0px 20px;
  box-shadow: 4px 4px 0 #000000;

  &:focus {
    outline: none;
  }

  span {
    color: #222222;
    position: absolute;
    top: 50%;
    transform: translateY(-60%);
    right: 15px;
    transition: all 0.3s ease 0s;
    transform: translate(-50%, -50%);
  }

  &:hover {
    background: #ffc947;
    color: #fff;
    transform: translateX(4px) translateY(4px);

    span {
      color: #fff;
      right: 20px;
    }
  }

  &.white {
    border: 1px solid #fff;
    color: #fff;

    span {
      color: #fff;
    }

    &:hover {
      background: #fff;
      color: #e0003b;

      span {
        color: #e0003b;
      }
    }
  }
`;

export const PrimaryButton1 = styled.button`
  ${buttonStyles}

  @media (max-width: 600px) {
    padding: 4px;
    display: flex;
    justify-content: space-around;
    width: 150px;
    margin-bottom: 10px;
  }
`;

export const PrimaryButton = styled.button`
  ${buttonStyles}
`;
function Banner() {
  const navigate = useNavigate();
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [wordEntered, setWordEntered] = useState("");
  const [searchbardata, setsearchbardata] = useState(false);

  const handleSearchToggle = () => {
    setIsSearchOpen(!isSearchOpen);
    setsearchbardata(!searchbardata)
    setWordEntered('')
    setFilteredData([])
  };

  const highlightSearchTerm = (text, searchTerm) => {
    const regex = new RegExp(searchTerm, "gi");
    return text.replace(regex, (match) => `<span class="highlight">${match}</span>`);
  };

  const textEnter = async (e) => {
    const searchTerm = e.target.value;
    setWordEntered(searchTerm);

    if (searchTerm.trim() !== '') {
      const result = await allinsitutes({ search: searchTerm });
      setFilteredData(result.institute || []);
      console.log(result);

    } else {
      setFilteredData([]);
    }
  };

  return (
    <>
      <section className='banner-area relative'>
        <div className="container">
          <div className="rows">
            <div className="banner-content">
              <div className="contents">
                <BannerH1>
                  Explore countless courses <br></br>from handpicked e-learning and offline institutions all in one place!
                </BannerH1>
                <br />
                <BannerP className='banner-p'>
                  Unlock your potential
                </BannerP>
                <br />

                <div className='button-div'>
                  <Link to="/search">
                    <button style={{ marginLeft: "10px", marginTop: "10px" }} className='button'>
                      Explore Courses
                    </button>
                  </Link>
                  <Link to="viewinstitutes">
                    <button style={{ marginLeft: "10px", marginTop: "10px" }} className='button'>
                      Institutes
                    </button>
                  </Link>
                </div>

              </div>
            </div>
            <div className="contents">
              <div><img src='/img/web_image.png' className='web-image' alt='webimage'></img></div>
            </div>
          </div>
        </div>
        {/* Search Icon for Mobile */}
        <div className='search-container'>
          {!isSearchOpen && <div className="search-icon-container" onClick={handleSearchToggle}>
            <SearchIcon style={{ color: 'black', fontSize: '24px' }} />
          </div>}


          {/* Show Search Input if Icon is Clicked */}
          {isSearchOpen && (
            <div className='search-input-container'>
              <InputBase
                type='text'
                value={wordEntered}
                onChange={textEnter}
                placeholder="Search..."
                className='search-input'
              />
              <IconButton className="close-icon" onClick={handleSearchToggle}>
                <CloseIcon />
              </IconButton>
            </div>
          )}

          {(filteredData.length !== 0 && searchbardata) && (
            <div style={{ marginTop: '47px', zIndex: '1', width: '100%', backgroundColor: '#fff', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', padding: '16px', borderRadius: '8px' }}>
              <p style={{ fontWeight: 'bold', marginBottom: '8px', fontSize: '16px' }}>
                Search results for "{wordEntered}"
              </p>
              {filteredData.slice(0, 4).map((value, key) => (
                <div key={key} style={{ marginBottom: '12px' }}>
                  {key === 0 && (
                    <div style={{ marginBottom: '8px' }}>
                      <span style={{ fontSize: '18px', fontWeight: 'bold' }}>{value.university_name}</span>
                      <div style={{ fontSize: '14px', marginTop: '4px', color: '#555' }}>
                        (Available courses: {filteredData.length})
                      </div>
                    </div>
                  )}
                  <p style={{ color: '#888', marginBottom: '4px' }}>Did you mean:</p>
                  <p
                    style={{ fontSize: '16px', color: '#007bff', cursor: 'pointer', marginBottom: '8px' }}
                    onClick={() => {
                      navigate(`/shortcourse/course-details/institute/${value.institution_id}`, {
                        state: { institute_id: value.institution_id },
                      });
                    }}
                    dangerouslySetInnerHTML={{
                      __html: highlightSearchTerm(value.university_name, wordEntered),
                    }}
                  ></p>
                  <p style={{ color: '#333', fontSize: '14px' }}>
                    Offered by {value.university_name}, Location: {value.city}, {value.state}
                  </p>
                </div>
              ))}
              <hr style={{ borderTop: '1px solid #ddd', margin: '16px 0' }} />
              <div>
                {/* <p style={{ fontSize: '16px', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                  <img src='/img/Search.svg' alt='search-button' style={{ marginRight: '8px' }} /> Courses for you
                </p> */}
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
                  {/* Example for course categories */}
                  {/* categories.map(category => (
            <Link key={category.category_id}>
              <h1 style={{ fontSize: '14px', color: '#007bff', cursor: 'pointer' }}>{category.category_name}</h1>
            </Link>
          )) */}
                </div>
              </div>
            </div>
          )}

        </div>
      </section>
    </>
  );
}

export default Banner;
