import React from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';


export default function Apicard({
  Institutionname,logo,
  CourseName,
}) {
  return (
    <>
      <div className='dynamic-cards' >
        <div className='card-institute'>
          <div className='institute-card-title' style={{alignItems:"center"}}>
            <img src={logo || '/logo just.png'} className='institute-logo' style={{width:"55px"}} alt='institute-logo'></img>
            <h1 >{Institutionname || ""}</h1>
          </div>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <VisibilityIcon style={{ fontSize: '23px', color: 'rgb(104 100 100)' }} /> {/* Material-UI eye icon */}
            <p style={{ fontSize: '10px', color: '#333', margin: 0 }}>5&nbsp;views</p>
          </div>


        </div>
        {/* <hr className='card-hr'></hr> */}
        <div className='card-category'>
          {/* <h1 className='card-title'>{CourseName || ''}</h1> */}
          {/* <p className='card-desc'>{CourseId}</p> */}
        </div>
        {/* <div className='card-footer'>
          <div className='course-fee'>
            <h1 className='footer-title'>Course Fee</h1>
            {CourseFee ? (<p>{"Rs. " + CourseFee + "/" + CourseFeeType.charAt(0).toUpperCase() + CourseFeeType.slice(1)}</p>) : (<p>NA</p>)}
          </div>
          <div className='vl'></div>
          <div className='course-duration'>
            <h1 className='footer-title'>Course Duration</h1>
            <p>{CourseDurationy && CourseDurationy + (CourseDurationy > 1 ? (' Years') : (' Year'))} {CourseDurationy && CourseDurationm ? ("and ") : ''}{CourseDurationm && CourseDurationm + (CourseDurationm > 1 ? (' Months') : (' Month'))} </p>
          </div>
        </div> */}
      </div>
    </>
  )
}
