import { useEffect, useRef, useState } from 'react';
import Apicard from './Apicard';
import Navbar from './Navbar';
import { State, City } from 'country-state-city';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { useFilter } from '../contexts/FilterContext';
import { allcategory, allinsitutes, alllocation, allprograms, fetchPrograms } from '../services/CommonApi';
import { Box, CircularProgress, IconButton, InputAdornment, MenuItem, Select, TextField, Typography, } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

import Contact from './Contact';

const ApiData = () => {

  const scrollRef = useRef(null); //filtering pagination 

  const navigate = useNavigate()
  const [categories, setCategories] = useState([])
  const [institutions, setInstitutions] = useState([])
  const [locations, setlocation] = useState([])
  // const [category_id, setCategoryFilter] = useState(0);
  // const [institution_id, setInstitutionFilter] = useState(0);
  const { category_id, selectedStateName, setselectedStateName, setCategoryFilter, institution_id, setInstitutionFilter, location, setLocationFilter, page, setPage } = useFilter();
  // const [location, setLocationFilter] = useState('');
  const [clickcategory, setclickcategory] = useState(false)
  const [clickinstitution, setclickinstitution] = useState(false)
  const [clicklocation, setclicklocation] = useState(false)
  const [empty, setEmpty] = useState(false);
  const [loadinginstitute, setLoadinginstitute] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);

  const [selectedState, setSelectedState] = useState('');
  // const [selectedStateName, setselectedStateName] = useState('')
  const [cities, setCities] = useState([]);
  const [filteredCities, setFilteredCities] = useState([]);
  const [availableStates, setAvailableStates] = useState([]);

  //**********************************************************88 */
  //first data fetching code
  // const [page, setPage] = useState(1);
  const [filteredData, setFilteredData] = useState([]);
  const totalPagesRef = useRef(1);
  const isFetchingRef = useRef(false); // To prevent multiple API calls
console.log(filteredData);

  const fetchfilter = async () => {
    try {
      setLoading(true);
      if (location != '') {
        setclicklocation(true)
      }
      const params = { category_id: category_id.id, location, institution_id: institution_id.id, offset: page };
      const response = await fetchPrograms(params);
      totalPagesRef.current = response.total_pages;
      if (response.courses && response.courses.length > 0) {
        setFilteredData(prevData => [...prevData, ...response.courses]);
        setLoading(false);
        setEmpty(false)
      } else if (page === 1) {
        setFilteredData([]);
        setEmpty(true)
        setLoading(false);
      }
      isFetchingRef.current = false;
    } catch (error) {
      console.error('Error fetching data', error);
      isFetchingRef.current = false;
    }
  };

  useEffect(() => {
    fetchfilter();
  }, [page, category_id, institution_id, location]);

  const handleScroll = () => {
    if (isFetchingRef.current) return;

    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = document.documentElement.clientHeight;

    if (scrollTop + clientHeight >= scrollHeight - 100) {
      if (page < totalPagesRef.current) {
        isFetchingRef.current = true;
        setPage(prevPage => prevPage + 1);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [page]);




  //*************************************************************** */

  const handleCategoryChange = (selectedCategoryId, selectedCategoryName) => {
    setPage(1);
    setFilteredData([]);
    setCategoryFilter(prev =>
      prev.id === selectedCategoryId
        ? { id: 0, name: '' }
        : { id: selectedCategoryId, name: selectedCategoryName }
    );
  };

  const handleInstitutionChange = (selectedInstitutionId, selectedUniversityName) => {
    setPage(1);
    setFilteredData([]);
    setInstitutionFilter(prev => prev.id === selectedInstitutionId ? { id: 0 } : { id: selectedInstitutionId, name: selectedUniversityName });
  };

  const handleLocationChange = (categoryId) => {
    setPage(1)
    setFilteredData([])
    setLocationFilter(prev => prev === categoryId ? '' : categoryId);
  };
  //****************Implement Selection time************* */

  const handleStateChange = (event) => {
    const selectedStateCode = event.target.value.split(',')[0];
    setSelectedState(selectedStateCode);
    setselectedStateName(event.target.value.split(',')[1])
    setCities(City.getCitiesOfState('IN', selectedStateCode));

    const filteredCitiesList = locations
      .filter(location => location.state === event.target.value.split(',')[1])
      .map(location => location.city);

    setFilteredCities(filteredCitiesList);

  };

  const handleCityChange = (e) => {
    setPage(1);
    setFilteredData([]);
    setLocationFilter(e.target.value);
  };

  //****************Implement Selection time************* */

  //************************************************************* */
  //click category so fetch
  const clickcategoryfilter = async () => {
    setclickcategory(!clickcategory)
    if (categories.length > 0) {
      return
    } else {
      const params = { data: true }
      const response = await allcategory(params)
      setCategories(response.sub_categories)
    }
  }
  // //click institute for filtering
  const clickinstitutionbutton = async () => {
    if (institutions.length > 0) {
      setclickinstitution(!clickinstitution);
    } else {
      setclickinstitution(!clickinstitution);
      await fetchInstitutions(1);
    }
  };
  //click location for filtering
  const clicklocationfilter = async () => {
    setclicklocation(!clicklocation)
    if (locations.length > 0) {
      return
    } else {
      const response = await alllocation()
      setlocation(response.locations)
      // Extract unique states from the backend locations
      const uniqueStates = [...new Set(response.locations.map(location => location.state))];
      setAvailableStates(uniqueStates);
    }

  }
  useEffect(() => {
    if (clickinstitution) {
      const divElement = scrollRef.current;
      divElement.addEventListener('scroll', handleScrollinstitute);
      if (currentPage <= totalPages) {
        handleScrollinstitute();
      }
      return () => {
        divElement.removeEventListener('scroll', handleScrollinstitute);
      };
    }
  }, [clickinstitution, currentPage, loadinginstitute]);

  //filtering fetch institutes
  const fetchInstitutions = async (page) => {
    if (loadinginstitute) return;
    setLoadinginstitute(true);
    try {
      const response = await allinsitutes({ limit: 5, page });
      const newInstitutions = response.institute.filter(newInst =>
        !institutions.some(existingInst => existingInst.institution_id.id === newInst.institution_id.id)
      );
      setInstitutions(prevInstitutions => [...prevInstitutions, ...newInstitutions]);
      setTotalPages(response.total_pages);
      setCurrentPage(page);
      // fetchLocations(response.institute);
    } finally {
      setLoadinginstitute(false);
    }
  };

  //handle institute scroll down
  const handleScrollinstitute = () => {
    if (!scrollRef.current) return;
    const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
    if (scrollTop + clientHeight >= scrollHeight - 10 && !loadinginstitute && currentPage < totalPages) {
      fetchInstitutions(currentPage + 1);
      setCurrentPage(prevPage => prevPage + 1);
    }
  };
  //************************************************************* */

  return (
    <div className='dup-body-wrap'>
      <Navbar title='SavvyPool' nav1='Home' nav2='List your institute' />
      <hr className='hr'></hr>

      {(category_id.id || institution_id.id || location) &&
        <><div style={{ margin: '10px' }}>

          <div style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '10px',
            alignItems: 'center',
            padding: '10px',
          }}>
            <p style={{ margin: '0 10px 0 0', fontWeight: 'bold' }}>Filters Applied:</p>
            {institution_id.id > 0 && (
              <div style={{
                backgroundColor: '#f7f7f7',
                border: '1px solid #FFEB3B',
                padding: '5px 10px',
                borderRadius: '15px',
                display: 'flex',
                alignItems: 'center',
                fontSize: '14px'
              }}>
                {institution_id.name}
                <span
                  style={{
                    marginLeft: '10px',
                    cursor: 'pointer',
                    // color: '#FFEB3B',
                    fontSize: '18px'
                  }}
                onClick={e=>setInstitutionFilter(0)}
                >
                  &#10005;
                </span>
              </div>
            )}
            {category_id.id > 0 && (
              <div style={{
                backgroundColor: '#f7f7f7',
                border: '1px solid #FFEB3B',
                padding: '5px 10px',
                borderRadius: '15px',
                display: 'flex',
                alignItems: 'center',
                fontSize: '14px'
              }}>{category_id.name}
                <span
                  style={{
                    marginLeft: '10px',
                    cursor: 'pointer',
                    // color: '#FFEB3B',
                    fontSize: '18px'
                  }}
                onClick={e=>setCategoryFilter(0)}
                >
                  &#10005;
                </span>
              </div>
            )}
            {location && (
              <div style={{
                backgroundColor: '#f7f7f7',
                border: '1px solid #FFEB3B',
                padding: '5px 10px',
                borderRadius: '15px',
                display: 'flex',
                alignItems: 'center',
                fontSize: '14px'
              }}>
                {location}
                <span
                  style={{
                    marginLeft: '10px',
                    cursor: 'pointer',
                    // color: '#FFEB3B',
                    fontSize: '18px'
                  }}
                onClick={e=>setLocationFilter('')}
                >
                  &#10005;
                </span>
              </div>
            )}
          </div>
        </div>
        </>
      }
      <div className='university-course-container'>

        <div className='searchpage-container'>

          <div className='filter-container'>
            <div className='filters'>
              <div style={{ marginBottom: '20px' }}>
                <Typography onClick={clickinstitutionbutton}
                  style={{
                    borderRadius: '20px',
                    borderColor: '#FFEB3B',
                    borderWidth: '2px',
                    padding: '2px 25px 2px 25px',
                    position: 'relative',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }} variant="h6">
                  Institution
                  <span style={{ position: 'absolute', right: '10px', fontSize: '27px' }}>&#9662;</span>
                </Typography>

                {clickinstitution && (
                  <Box ref={scrollRef} style={{
                    padding: '1px', border: '2px solid grey',
                    borderRadius: '10px', marginTop: '10px', height: '244px',
                    overflowX: 'hidden', overflowY: 'auto', minHeight: '250px'
                  }} >
                    {institutions ? (institutions.map((institution) => (
                      <Box
                        key={institution.institution_id.id}
                        onClick={() => handleInstitutionChange(institution.institution_id, institution.university_name)}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          cursor: 'pointer',
                          padding: '5px 10px', // Added padding for better spacing
                        }}
                      >
                        {/* University Name - Aligned to the left */}
                        <Typography style={{ flex: 1, textAlign: 'left' }}>
                          {institution.university_name}
                        </Typography>

                        {/* Selection Indicator - Aligned to the right */}
                        <Box
                          style={{
                            width: '22px',
                            height: '22px',
                            borderRadius: '50%',
                            border: '2px solid grey',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: institution_id.id === institution.institution_id ? '#1976d2' : 'transparent',
                          }}
                        >
                          {institution_id.id === institution.institution_id && (
                            <Box
                              style={{
                                width: '7px',
                                height: '7px',
                                borderRadius: '50%',
                                backgroundColor: 'white',
                              }}
                            />
                          )}
                        </Box>
                      </Box>

                    ))) : (<CircularProgress />)}
                  </Box>
                )}
              </div>

              <div style={{ marginBottom: '20px' }}>
                <Typography onClick={clickcategoryfilter}
                  style={{
                    borderRadius: '20px',
                    borderColor: '#FFEB3B',
                    borderWidth: '2px',
                    padding: '2px 25px 2px 25px',
                    position: 'relative',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }} variant="h6">
                  Category
                  <span style={{ position: 'absolute', right: '10px', fontSize: '27px' }}>&#9662;</span>
                </Typography>
                {clickcategory && (
                  <Box style={{
                    padding: '10px', border: '2px solid grey',
                    borderRadius: '10px', marginTop: '10px', height: '244px', // Adjust the height as needed
                    overflowX: 'hidden', overflowY: 'auto',
                  }} >
                    {categories.map((category) => (
                      <Box key={category.category_id.id}
                        onClick={() => handleCategoryChange(category.category_id, category.category_name)}
                        style={{
                          display: 'flex', alignItems: 'center', cursor: 'pointer',
                          marginBottom: '10px', justifyContent: 'space-between',
                        }}>
                        <Typography>{category.category_name}</Typography>
                        <Box style={{
                          width: '22px', height: '22px', borderRadius: '50%',
                          border: '2px solid grey', marginLeft: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center',
                          backgroundColor: category_id.id === category.category_id ? '#1976d2' : 'transparent',
                        }} >
                          {category_id.id === category.category_id && (
                            <Box style={{
                              width: '7px', height: '7px',
                              borderRadius: '50%', backgroundColor: 'white',
                            }}
                            />)}
                        </Box>
                      </Box>
                    ))}
                  </Box>
                )}
              </div>

              <div style={{ marginBottom: '20px' }}>
                <Typography onClick={clicklocationfilter}
                  style={{
                    borderRadius: '20px', borderColor: '#FFEB3B', borderWidth: '2px',
                    padding: '2px', paddingLeft: '25px', position: 'relative', cursor: "pointer"
                  }} variant="h6">
                  Location
                  <span style={{ position: 'absolute', right: '10px', fontSize: '27px' }}>&#9662;</span>
                </Typography>
                {clicklocation && (
                  <Box style={{
                    padding: '10px', border: '2px solid grey',
                    borderRadius: '10px', marginTop: '10px', height: '170px', // Adjust the height as needed
                    overflowX: 'hidden', overflowY: 'auto',
                  }} >
                    {/* State Dropdown */}
                    <TextField
                      select={!selectedStateName}
                      label="State"
                      value={selectedStateName}
                      variant="outlined"
                      fullWidth
                      onChange={handleStateChange}
                      style={{ marginBottom: '10px' }}
                    >
                      <MenuItem value="">
                        <em>Select State</em>
                      </MenuItem>
                      {State.getStatesOfCountry('IN')
                        .filter(state => availableStates.includes(state.name))
                        .map((state) => (
                          <MenuItem key={state.isoCode} value={`${state.isoCode},${state.name}`}>
                            {state.name}
                          </MenuItem>
                        ))}
                    </TextField>
                    {/* City Dropdown */}
                    <TextField
                      select={!location}
                      value={location}
                      label="City"
                      variant="outlined"
                      fullWidth
                      onChange={handleCityChange}
                      style={{ marginBottom: '10px' }}
                      disabled={!selectedState} // Disable if no state selected
                      InputProps={{
                        endAdornment: location && (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="clear city selection"
                              onClick={e => (setLocationFilter(''), setselectedStateName(''), setSelectedState(''))}
                              edge="end"
                            >
                              <ClearIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    >
                      <MenuItem value="">
                        <em>Select City</em>
                      </MenuItem>
                      {filteredCities.map((city) => (
                        <MenuItem key={city} value={city}>
                          {city}
                        </MenuItem>
                      ))}
                    </TextField>


                    
                  </Box>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='university-course'>
          {filteredData.length > 0 ? (
            filteredData.map((university, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3, delay: 0.1 }}
              >
                <div key={university.course_id} onClick={() => {
                  navigate(`/shortcourse/course-details/institute/${university.institution_name}`, { state: { institute_id: university.institution_id } })
                  // navigate(`/shortcourse/course-details/${university.course_id}`, { state: { course_id: university.course_id } })
                }}>
                  <Apicard
                    Institutionname={university.institution_name}
                    CourseName={university.course_name}
                    Coursedescription={university.course_description}
                    CourseFee={university.course_fee}
                    CourseDurationy={university.course_duration_year || ''}
                    CourseDurationm={university.course_duration_month || ''}
                    CourseFeeType={university.course_fee_type}
                    logo={university.logo_url}
                    city={university.city}
                    state={university.state}
                    country={university.country}
                    CourseId={university.course_id}
                  />

                </div>
              </motion.div>

            ))
          ) : (
            <div className='course-none'>
              {!empty ? (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                <CircularProgress />
              </div>) :
                (<><img src='/img/error.svg' alt='error svg'></img>
                  <p>The course you were looking for</p>
                  <h1>Was not found.</h1></>)
              }
            </div>
          )}
          {/* Circular Progress while loading next page */}
          {loading && (
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', width: '100%' }}>
              <CircularProgress />
            </div>
          )}
        </div>
      </div>
      <Contact />
    </div>
  );
};

export default ApiData;